// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhwQWJu7OXS-WzSvOmdNNkDq5WGMYMaqc",
  authDomain: "ecommercedbcloneclone6clone.firebaseapp.com",
  projectId: "ecommercedbcloneclone6clone",
  storageBucket: "ecommercedbcloneclone6clone.firebasestorage.app",
  messagingSenderId: "797029407948",
  appId: "1:797029407948:web:56676360cd6486eb69f669"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;